<template>
    <Layout>
        
        <loading-flux :value="loading"></loading-flux>
        
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card overflow-hidden">
                    <div class="bg-soft bg-primary" style="background-image: url(https://fluxsolar.cl/app/assets/img/bg01d.jpg);">
                        <div class="row">
                            <div class="col-7">
                                <div class="p-4" style="color:#fff">
                                    <h5 class="" style="color:#fff">
                                        Restaurar Contraseña
                                    </h5>
                                    <p>
                                        Restaurar contraseña Administrador de
                                        proyectos.
                                    </p>
                                </div>
                            </div>
                            <div class="col-5 align-self-center">
                                <img
                                    src="/imagenes/logo-light.png"
                                    alt
                                    class="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div>
                            <span>
                                <div class="avatar-md profile-user-wid mb-4">
                                    <span
                                        class="avatar-title rounded-circle bg-light"
                                    >
                                        <img
                                            src="@/assets/images/logo-icon.png"
                                            alt
                                            height="34"
                                        />
                                    </span>
                                </div>
                            </span>
                        </div>

                        <div class="p-2">
                            <b-alert
                                v-model="isResetError"
                                class="mb-4"
                                variant="danger"
                                dismissible
                                >{{ error }}</b-alert
                            >
                            <form @submit.prevent="tryToReset">
                                <div class="mb-3">
                                    <label for="useremail">Email</label>
                                    <input
                                        type="email"
                                        v-model="email"
                                        class="form-control"
                                        id="useremail"
                                        placeholder="Ingresar Email"
                                        :class="{
                                            'is-invalid':
                                                submitted && $v.email.$error,
                                        }"
                                    />
                                    <div
                                        v-if="submitted && $v.email.$error"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!$v.email.required"
                                            >Email Requerido.</span
                                        >
                                        <span v-if="!$v.email.email"
                                            >Ingrese un email valido.</span
                                        >
                                    </div>
                                </div>
                                <div class="mb-3 row mb-0">
                                    <div class="col-12 text-end">
                                        <button
                                            class="btn btn-primary w-md"
                                            type="submit"
                                        >
                                            Restaurar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->

                <div class="mt-5 text-center">
                    <p>
                        La recuerdas ?
                        <router-link to="/login" class="fw-medium text-primary"
                            >Iniciar Sesión</router-link
                        >
                    </p>
                    <p>
                        © {{ new Date().getFullYear() }} FluxSolar
                        <i class="mdi mdi-heart text-danger"></i>
                    </p>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </Layout>
</template>
<script>
import Layout from "../../layouts/auth";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";

import { required, email } from "vuelidate/lib/validators";

export default {
    page: {
        title: "Forgot Password",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
    },
    data() {
        return {
            email: "",
            submitted: false,
            loading:false,
            error: null,
            tryingToReset: false,
            isResetError: false,
        };
    },
    validations: {
        email: {
            required,
            email,
        },
    },
    methods: {
        ...authMethods,
        tryToReset() {
            this.loading = true;
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.restaurarContrasena({
                email: this.email,
            })
                .then((res) => {
                    if (res.status == 200) {
                        this.$router.push({
                            name: "login",
                        });
                    }
                    this.loading = false;
                    this.tryingToReset = false;
                    this.isResetError = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.tryingToReset = false;
                    this.error = error ? error : "";
                    this.isResetError = true;
                });
        },
    },
};
</script>

<style lang="scss" module></style>
